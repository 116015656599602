<template>
  <div>
    <v-card class="mb-4 mt-1 pa-3 pt-0 bordered-card">
      <div
        v-if="(loading || loadingTimeSlots) && !noneAvailable"
        class="mt-3 text-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="mr-2"
        ></v-progress-circular
        >Checking for available appointments...
      </div>
      <div v-else-if="!noneAvailable">
        <div class="d-flex mb-2 mt-3">
          <v-menu
            v-model="openCalendar"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
          >
            <template #activator="{ on }">
              <v-btn outlined rounded color="primary" v-on="on">
                <div class="d-flex">
                  <v-icon color="secondary" class="mr-2">mdi-calendar</v-icon>
                  <span class="pick-label">Pick Date</span>
                </div>
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <div style="position: relative">
              <v-date-picker
                v-model="datestring"
                color="primary"
                header-color="secondary"
                :title-date-format="formatDate"
                :min="todayString"
                :allowed-dates="allowedDates"
                reactive
                :picker-date.sync="pickerDate"
                :class="{ loading: loadingAllowedDates }"
                :disabled="loadingAllowedDates"
                @input="openCalendar = false"
              ></v-date-picker>
              <div
                v-if="loadingAllowedDates"
                class="loading-icon elevation-3 white pa-3"
              >
                <v-progress-circular
                  color="primary"
                  class="mr-2"
                  indeterminate
                ></v-progress-circular
                >Loading month...
              </div>
            </div>
          </v-menu>

          <div class="d-flex flex-column ml-3">
            <span class="caption grey--text"
              >Showing available appointments for</span
            >
            <span class="primary--text">
              <b>{{ selectedSlot.day }}</b>
            </span>
          </div>
        </div>
        <v-divider></v-divider>
        <div v-if="!loadingTimeSlots" class="my-3 d-flex flex-wrap">
          <v-btn
            v-for="slot in allTimeSlots"
            :key="slot.start_hour"
            color="secondary"
            :outlined="!isSelected(slot)"
            :disabled="slot.disabled"
            class="ma-1 slot-btn"
            @click="selectSlot(slot)"
          >
            {{ formatTimeSlot(slot.start_time, false) }} -
            {{ formatTimeSlot(slot.end_time, true) }}
          </v-btn>
        </div>
        <div v-if="timeSlotDisclaimer" class="caption">
          *{{ timeSlotDisclaimer }}
        </div>
        <div v-if="!loadingTimeSlots" class="d-flex justify-end mt-3 mr-1">
          <v-dialog
            v-model="dialog"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            :transition="
              $vuetify.breakpoint.smAndDown
                ? 'dialog-bottom-transition'
                : 'dialog-transition'
            "
            max-width="768"
          >
            <template #activator="{ on }">
              <v-btn outlined color="secondary" v-on="on">
                <v-icon color="warning" class="mr-2" small
                  >mdi-weather-night</v-icon
                >After Hours
              </v-btn>
            </template>
            <v-card>
              <v-toolbar dark color="secondary">
                <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>After Hours Appointment</v-toolbar-title>
              </v-toolbar>
              <div class="after-hours-dialog py-6 text-center">
                <p>{{ serviceCategory.name }} at</p>
                <p
                  v-if="
                    address.name !== `${address.street}, ${address.zip_code}`
                  "
                >
                  {{ address.name }}
                </p>
                <p>{{ address.street }}, {{ address.zip_code }}</p>
                <v-divider class="my-4"></v-divider>
                <p>To schedule an after hours appointment for</p>
                <p>
                  <b>{{ selectedSlot.day }}</b
                  >,
                </p>
                <p>
                  please give our friendly customer service representatives a
                  call.
                </p>
                <p class="my-4">
                  <a
                    :href="`tel:${$store.state.tenant.customizations.General.phone}`"
                  >
                    <b>
                      {{ $store.state.tenant.customizations.General.phone }}
                    </b>
                  </a>
                </p>
                <v-btn
                  outlined
                  color="secondary"
                  rounded
                  @click="dialog = false"
                  >Back to schedule</v-btn
                >
              </div>
            </v-card>
          </v-dialog>
        </div>
        <div v-else class="my-3 d-flex flex-wrap justify-center align-center">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mr-2"
          ></v-progress-circular
          >Getting available times...
        </div>
      </div>
      <div v-if="!loading && noneAvailable" class="mt-3 text-center">
        <p>
          Online booking isn't currently available
          <br />for this service.
        </p>
        <p>
          Our friendly customer service agents are ready to assist you at
          <a :href="`tel:${$store.state.tenant.customizations.General.phone}`">
            <b>{{ $store.state.tenant.customizations.General.phone }}</b> </a
          >.
        </p>
      </div>
      <span v-if="errorMessage" class="caption error--text">
        {{ errorMessage }}
      </span>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  addDays,
  formatDate,
  formatTimeByTimeZone,
  getStartOfDay,
  prettyFormat
} from "../lib/date";
import AvailabilityMixin from "../mixins/availabilityMixin";

export default {
  name: "TimeSlots",
  mixins: [AvailabilityMixin],
  props: {
    serviceCategory: {
      type: Object,
      default: () => {}
    },
    address: {
      type: Object,
      default: () => {}
    },
    handleSelectSlot: {
      type: Function,
      default: () => {}
    },
    handleNoSlotsAvailable: {
      type: Function,
      default: () => {}
    },
    errorMessage: {
      type: String,
      default: ""
    },
    resetSelection: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      selectedSlot: null,
      todayString: formatDate(new Date()),
      date: new Date(),
      datestring: formatDate(new Date()),
      loading: true,
      loadingTimeSlots: false,
      loadingAllowedDates: false,
      openCalendar: false,
      noneAvailable: false,
      allowedDates: () => false,
      pickerDate: null,
      dialog: false
    };
  },
  computed: {
    ...mapState({
      timeSlotDisclaimer({ tenant }) {
        if (!tenant || !tenant.customizations) {
          return "";
        }
        const { customizations } = tenant;
        return (customizations.General.time_slot_disclaimer || "").trim();
      }
    }),
    availableDates() {
      return this.$store.state.availableDates;
    }
  },
  watch: {
    datestring(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleDateChange(getStartOfDay(addDays(new Date(newValue), 1)));
      }
    },
    async pickerDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.getMonthAvailability(newValue);
      }
    },
    serviceCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetDatesCache();
        this.getAutoSuggestedSlot();
      }
    },
    address(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetDatesCache();
        this.getAutoSuggestedSlot();
      }
    }
  },
  async mounted() {
    this.getAutoSuggestedSlot();
  },
  methods: {
    async getAutoSuggestedSlot() {
      this.loading = true;
      this.selectedSlot = await this.autoSuggest(
        this.date,
        this.serviceCategory,
        this.address.zip_code
      );
      if (this.selectedSlot) {
        // Auto select first slot
        // this.handleSelectSlot(this.selectedSlot);
        this.date = this.selectedSlot.date;
        this.selectedSlot = { day: this.selectedSlot.day };
        this.noneAvailable = false;
      } else {
        this.noneAvailable = true;
        if (this.handleNoSlotsAvailable) {
          this.handleNoSlotsAvailable();
        }
      }
      this.loading = false;
    },
    async getMonthAvailability(datestr) {
      // parse date from v-date-picker
      const year = datestr.substring(0, 4);
      const month = datestr.substring(5, datestr.length);
      const day = "01";
      const dateKey = `${year}-${month}-${day}`;
      const date = new Date(dateKey);

      this.loadingAllowedDates = true;
      const pickerDate = addDays(date, 1);
      try {
        await this.fetchAvailableDates(
          pickerDate,
          this.serviceCategory,
          this.address.zip_code
        );
      } catch (e) {
        this.loadingAllowedDates = false;
      }

      this.allowedDates = (date) => this.$store.state.availableDates[date];
      this.loadingAllowedDates = false;
    },
    isSelected(slot) {
      return slot.start_hour === this.selectedSlot.start_hour;
    },
    selectSlot(slot) {
      const dayName = prettyFormat(this.date);
      this.selectedSlot = { ...slot, day: dayName };
      this.handleSelectSlot({ ...slot, date: this.date });
    },
    formatDate(date) {
      return prettyFormat(addDays(date, 1));
    },
    async handleDateChange(date) {
      // Load available time slots for selected date
      this.date = date;
      this.loadingTimeSlots = true;
      await this.fetchTimeSlots(
        date,
        this.serviceCategory,
        this.address.zip_code
      );
      this.loadingTimeSlots = false;
      // Get first time slot for selected date
      const selectedSlot = this.getFirstAvailableTimeSlot(
        date,
        this.$store.state.timeSlots
      );
      if (this.resetSelection) {
        this.resetSelection();
      }
      // this.selectSlot(this.selectedSlot);
      if (selectedSlot) {
        this.selectedSlot = { day: selectedSlot.day };
      }
    },
    resetDatesCache() {
      this.noneAvailable = false;
      this.selectedSlot = null;
      this.date = new Date();
      this.$store.commit("replaceState", {
        state: "availableDates",
        data: {}
      });
      this.$store.commit("replaceState", {
        state: "fetchedMonths",
        data: {}
      });
    },
    formatTimeSlot(time, isEndTime) {
      const timeZone = this.address.time_zone;
      return formatTimeByTimeZone(time, timeZone, isEndTime);
    }
  }
};
</script>

<style lang="scss" scoped>
.pick-label {
  width: 40px;
  display: inline-block;
  white-space: normal;
  text-align: center;
}
.slot-btn {
  flex: 0 1 calc(33.33% - 8px);
}
.loading {
  position: relative;
  &::before {
    content: "";
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0.8;
    position: absolute;
    z-index: 2;
  }
}
.loading-icon {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 3;
  border-radius: 3px;
  transform: translateX(-50%);
  min-width: 185px;
}
.after-hours-dialog {
  p {
    margin: 0;
  }
}
</style>

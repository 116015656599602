<template>
  <div>
    <span class="overline block font-bold mb-2 text-left">Primary Email</span>
    <div class="d-flex align-start full-width primary-row">
      <v-text-field
        outlined
        type="email"
        label="Primary"
        hint="ex: name@domain.com"
        :rules="emailRules"
        :error-messages="emailValidationErrorMessage"
        class="mr-2"
        :value="primaryEmail.email_address"
        @input="updateEmailAddress($event, primaryEmail)"
      />
      <v-text-field
        :value="primaryEmail.label"
        outlined
        label="Label"
        hint="ex: Primary"
        class="email-label-field"
        @input="updateLabel($event, primaryEmail)"
      />
    </div>
    <span class="overline block font-bold mb-2 text-left"
      >Additional Email(s)</span
    >
    <div class="emails-cont">
      <div v-for="email in additionalEmails" :key="email.id">
        <div class="d-flex align-center full-width">
          <v-text-field
            outlined
            type="email"
            :label="getLabel(email)"
            hint="ex: name@domain.com"
            :rules="emailRules"
            :error-messages="emailValidationErrorMessage"
            class="mr-2"
            :value="email.email_address"
            @input="updateEmailAddress($event, email)"
          />
          <v-text-field
            :value="email.label"
            outlined
            label="Label"
            hint="ex: Primary"
            class="email-label-field"
            @input="updateLabel($event, email)"
          />
          <ActionsMenu
            :actions="actions"
            class="action-menu-icon"
            @delete="handleDelete(email)"
          />
        </div>
      </div>
    </div>
    <div class="d-flex">
      <v-btn color="primary" rounded outlined @click="addEmail">
        <v-icon left>mdi-plus</v-icon>Add Email
      </v-btn>
    </div>
  </div>
</template>

<script>
import debounce from "@/lib/debounce";
import { generateUID } from "@/lib/helpers";

import ValidationRules from "@/mixins/validationRules";
export default {
  mixins: [ValidationRules],
  props: {
    emails: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formData: [...this.emails]
    };
  },
  computed: {
    primaryEmail() {
      return this.formData.find((email) => email.primary) || this.formData[0];
    },
    additionalEmails() {
      return this.formData.filter((email) => !email.primary);
    },
    actions() {
      return [
        {
          label: "Delete",
          icon: "fa-regular fa-trash-can",
          eventName: "delete",
          className: "action-menu-item"
        }
      ];
    }
  },
  watch: {
    formData(newValue) {
      this.$emit("update", newValue);
    }
  },
  methods: {
    addEmail() {
      this.formData = [
        ...this.formData,
        {
          id: generateUID(),
          new: true,
          email_address: "",
          label: "",
          primary: false
        }
      ];
    },
    getLabel(email) {
      return email.primary || email === this.primaryEmail
        ? "Primary Email"
        : "Additional Email";
    },
    handleDelete(data) {
      this.formData = this.formData.filter((email) => email.id !== data.id);
    },
    validateEmailChange: debounce(function (email) {
      this.validateEmail(email);
    }, 500),
    updateEmailAddress(value, email) {
      this.validateEmailChange(value);
      this.formData = this.formData.map((_email) => {
        if (email.id === _email.id) {
          return {
            ..._email,
            email_address: value
          };
        }
        return _email;
      });
    },
    updateLabel(value, email) {
      this.formData = this.formData.map((_email) => {
        if (email.id === _email.id) {
          return {
            ..._email,
            label: value
          };
        }
        return _email;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.emails-cont {
  padding-top: 5px;
  max-height: 300px;
  overflow: scroll;
}
::v-deep(.action-menu-trigger) {
  margin-top: -25px;
  padding: 20px !important;
  cursor: pointer;
}
.email-label-field {
  flex: 1;
  min-width: 100px;
}
.primary-row {
  padding-right: 45px;
}
</style>
<style>
.action-menu-item {
  font-size: 1.2em;
}
</style>

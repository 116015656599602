<template>
  <div>
    <div class="mb-6">
      <div><strong>Payment Amount</strong></div>
      <InputWrapper
        label="Pay balance due or enter other amount:"
        :error="errorMessages.paymentAmount"
        @clear="paymentAmount = 0"
      >
        <CurrencyInput
          v-model="paymentAmount"
          :disabled="invoice?.financials.balance_cents <= 0"
          class="payment-amount"
        />
      </InputWrapper>
    </div>
    <div v-if="isUsingSavedPaymentMethod" class="mb-6">
      <div><strong>Payment Method</strong></div>
      <Card
        ><PaymentMethodOnFile
          @payment-method-deleted="handlePaymentMethodDeleted"
      /></Card>
    </div>
    <div v-if="isUsingSavedPaymentMethod" class="payWithDifferentMethod pb-12">
      <Card class="d-flex" is-button @click="isUsingSavedPaymentMethod = false">
        <span>Pay with different method (New card or ACH)</span>
        <i class="ml-auto fa-solid fa-square-plus"></i>
      </Card>
    </div>
    <div v-if="!isUsingSavedPaymentMethod">
      <PaymentMethods
        ref="paymentMethodsComponent"
        :payment-methods="filteredPaymentMethods"
        @tab-switch="handlePaymentMethodTabSwitch"
      />
    </div>

    <div
      class="paymentActions"
      :class="{ 'with-background': !isUsingSavedPaymentMethod }"
    >
      <div
        v-if="errorMessages.paymentProcessingError"
        class="errorMessage paymentProcessingError mb-4"
      >
        <i class="fa fa-exclamation-circle mr-1"></i>
        <span>{{ errorMessages.paymentProcessingError }}</span>
      </div>
      <div class="flex justify-center button-content">
        <v-btn
          rounded
          block
          large
          :loading="makeAPaymentButtonLoading"
          color="primary"
          type="button"
          @click="handlePayment"
          >Make Payment</v-btn
        >
      </div>
    </div>

    <!-- Payment Authorization Confirmation Modals -->
    <ACHAuthorizationModal
      :show="showPaymentConfirmationModalType === 'ach'"
      :amount-in-cents="Math.round(paymentAmount * 100)"
      :vendor="vendor"
      @confirm="handlePaymentConfirm()"
      @cancel="showPaymentConfirmationModalType = ''"
    />
    <ProcessingPayment v-model="showProcessingPaymentModal" />
    <PaymentSuccess
      v-bind="confirmationDetails"
      v-model="showPaymentSuccessModal"
      @confirm="emit('payment-success-confirmed')"
    />
  </div>
</template>

<script lang="ts" setup>
import { debounce } from "lodash";
import { Invoice } from "serviceshift-ui/shared/src/typings/invoice";
import {
  PaymentMethodType,
  storablePaymentMethods
} from "serviceshift-ui/shared/src/typings/paymentMethods";
import { computed, onMounted, ref, watch } from "vue";
import { scrollToNearestErrorMessage } from "@/lib/errorMessage";

import Card from "@/components/Card.vue";
import ACHAuthorizationModal from "@/modules/paymentMethods/components/modals/ACHAuthorizationModal.vue";
import PaymentSuccess from "@/modules/paymentMethods/components/modals/PaymentSuccess.vue";
import ProcessingPayment from "@/modules/paymentMethods/components/modals/ProcessingPayment.vue";
import PaymentMethodOnFile from "@/modules/paymentMethods/components/PaymentMethodOnFile.vue";
import PaymentMethods from "@/modules/paymentMethods/components/PaymentMethods.vue";
import usePaymentMethods from "@/modules/paymentMethods/usePaymentMethods";
import CurrencyInput from "../components/CurrencyInput.vue";
import InputWrapper from "./InputWrapper.vue";

const props = defineProps({
  vendor: {
    type: String,
    default: () => ""
  },
  customerMode: {
    type: Boolean,
    default: () => false
  },
  invoice: {
    type: Object as () => Invoice,
    default: () => null
  }
});

const emit = defineEmits([
  "submit",
  "payment-failed",
  "payment-method-deleted",
  "payment-success",
  "payment-success-confirmed"
]);

const errorMessages = ref({
  paymentAmount: "",
  paymentProcessingError: ""
});
const formData = ref<{
  [key: string]: any;
  transaction_type: PaymentMethodType;
  payment_method_id: number | null;
} | null>(null);
const { createTransaction, customer, storedPaymentMethods } =
  usePaymentMethods();
const makeAPaymentButtonLoading = ref(false);
const paymentAmount = ref(0);
const paymentMethodsComponent = ref<any>(null);
const saving = ref(false);
const isUsingSavedPaymentMethod = ref(true);
const showPaymentConfirmationModalType = ref("");
const showProcessingPaymentModal = ref(false);
const showPaymentSuccessModal = ref(false);
const confirmationDetails = ref<Record<string, any> | null>(null);

onMounted(() => {
  init();
});

watch(customer, () => {
  init();
});

function handlePaymentMethodTabSwitch() {
  clearErrorMessages();
}

function init() {
  if (!storedPaymentMethods.value.length) {
    isUsingSavedPaymentMethod.value = false;
  }
}

const filteredPaymentMethods = computed(() => {
  if (props.customerMode) return storablePaymentMethods;
  return undefined;
});

const paymentAmountInCents = computed(() => {
  return Math.round(paymentAmount.value * 100);
});

function getMinimumPaymentRequired(transactionType: PaymentMethodType) {
  const transactionTypesWithFees = ["card", "ach", "payment_method"];
  return transactionTypesWithFees.includes(transactionType) ? 100 : 1;
}

function handlePaymentMethodDeleted() {
  emit("payment-method-deleted");
}

watch(
  () => props.invoice,
  (newValue, oldValue) => {
    // Should update amount if balance changes
    // but not if they are currently doing a split payment or something
    if (
      (!oldValue && newValue) ||
      (newValue &&
        oldValue &&
        newValue?.financials.balance_cents !==
          oldValue?.financials.balance_cents)
    ) {
      paymentAmount.value = newValue?.financials.balance_cents / 100;
    }
  },
  {
    immediate: true
  }
);

function clearErrorMessages() {
  Object.keys(errorMessages.value).forEach((key) => {
    errorMessages.value[key] = "";
  });
}

// Once we get the data from the Iframe, we should store a copy of it so
// we don't have to make multiple ajax calls
async function getFormData() {
  formData.value = null;
  if (isUsingSavedPaymentMethod.value) {
    formData.value = {
      transaction_type: "payment_method",
      payment_method_id: storedPaymentMethods.value[0].id
    };
  } else {
    formData.value = await paymentMethodsComponent.value.getData();
  }
  return formData.value;
}

const handlePayment = debounce(async function () {
  makeAPaymentButtonLoading.value = true;
  clearErrorMessages();
  try {
    await getFormData();
    const minAmount = getMinimumPaymentRequired(
      formData.value!.transaction_type
    );

    // Double check that the min payment is met
    if (paymentAmountInCents.value < minAmount) {
      const errorMessage = `Minimum payment amount of $${
        minAmount / 100
      } is required`;
      errorMessages.value.paymentAmount = errorMessage;
      makeAPaymentButtonLoading.value = false;
      throw new Error(errorMessage);
    }

    // For certain payments, we may need a confirmation modal first (like ACH)
    if (!isUsingSavedPaymentMethod.value) {
      if (formData.value!.transaction_type === "ach") {
        showPaymentConfirmationModalType.value = "ach";
        makeAPaymentButtonLoading.value = false;
        return;
      }
    }
    makeAPaymentButtonLoading.value = false;
    return handlePaymentConfirm();
  } catch (e) {
    scrollToNearestErrorMessage();
  }
  makeAPaymentButtonLoading.value = false;
}, 250);

async function handlePaymentConfirm() {
  confirmationDetails.value = {};
  showPaymentConfirmationModalType.value = "";
  showProcessingPaymentModal.value = true;
  saving.value = true;
  emit("submit");
  try {
    const data = {
      amount_cents: paymentAmountInCents.value,
      ...formData.value
    };
    const results = await createTransaction({
      invoice: props.invoice,
      data
    });
    emit("payment-success");
    confirmationDetails.value = {
      paymentType: formData.value!.transaction_type,
      amountInCents: results.data.amount_cents,
      date: results.data.transaction_time,
      confirmationNumber: `${results.data.reference_id}`,
      invoiceNumber: `${results.data.invoice_id}`
    };
    showPaymentSuccessModal.value = true;
    paymentAmount.value = props.invoice!.financials!.balance_cents / 100;
    if (paymentMethodsComponent.value) {
      paymentMethodsComponent.value.resetForms();
    }
  } catch (e: any) {
    const genericPaymentProcessingError =
      "Your payment could not be processed. Please try again or try a different payment method.";
    errorMessages.value.paymentProcessingError = genericPaymentProcessingError;
    emit("payment-failed", genericPaymentProcessingError);
    scrollToNearestErrorMessage();
  }
  setTimeout(() => {
    showProcessingPaymentModal.value = false;
  }, 1000);
  saving.value = false;
}
</script>

<style scoped lang="scss">
:deep(.money-input) {
  font-weight: bold;
  width: 130px;
  flex: 1;
  @media only screen and (max-width: 600px) {
    width: 90px;
  }
}
.paymentProcessingError {
  color: #f00;
  font-weight: bold;
}
.payWithDifferentMethod {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #de683a;
}
.paymentActions {
  &.with-background {
    padding: 12px;
    background: #fff;
  }
}
</style>

import { formatToTimeZone } from "date-fns-timezone";

export const formatDate = (date, format, separator = "-") => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return format === "american"
    ? [month, day, year].join(separator)
    : [year, month, day].join(separator);
};

export const formatTime = (datestr) => {
  const date = new Date(datestr);
  let hour = date.getHours();
  let minutes = date.getMinutes().toString();

  const suffix = hour > 12 ? "pm" : "am";
  hour = hour > 12 ? hour - 12 : hour;
  hour = hour === "00" ? "12" : hour;
  // zero pad minutes
  minutes = minutes.length < 2 ? `0${minutes}` : minutes;
  return `${hour}:${minutes} ${suffix}`;
};

export const getLastDayofMonth = (date) =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const getYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getNextMonth = (date) =>
  new Date(date.getFullYear(), date.getMonth() + 1, 1);

export const prettyFormat = (date) => {
  const now = new Date();
  const isToday = date.getDate().toString() === now.getDate().toString();
  const formatOptions = {
    weekday: "long",
    month: "numeric",
    day: "numeric",
    year: "numeric"
  };
  if (isToday) {
    delete formatOptions.weekday;
  }
  const formattedDate = date.toLocaleDateString("en-us", formatOptions);
  return isToday ? `TODAY, ${formattedDate}` : formattedDate;
};

export const parseDate = (dateString) => {
  if (dateString) {
    const parts = dateString.split("-");
    return new Date(parts[0], parseInt(parts[1], 10) - 1, parts[2]);
  }
};

export const getStartOfDay = (date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const isDateGreaterThan = (start, end) => {
  // Compares two dates without time
  const startDate = getStartOfDay(start);
  const endDate = getStartOfDay(end);
  // Make sure date is today or later
  return startDate.getTime() >= endDate.getTime();
};

export const isDateGreaterThanToday = (date) => {
  // Compares two dates without time
  const startOfToday = getStartOfDay(new Date());
  date.setHours(0, 0, 0, 0);
  // Make sure date is today or later
  return date.getTime() >= startOfToday.getTime();
};

export const isToday = (date) => {
  // Compares two dates without time
  const startOfToday = getStartOfDay(new Date());
  date.setHours(0, 0, 0, 0);
  // Make sure date is today or later
  return date.getTime() === startOfToday.getTime();
};

export const isDate = (date) =>
  Object.prototype.toString.call(date) === "[object Date]";

export const monthsDifference = (start, end) => {
  if (!isDate(start) || !isDate(end)) {
    return 0;
  }
  // Gets difference in months of two dates
  const startMonth = start.getMonth() + 1;
  const endMonth = end.getMonth() + 1;
  const yearMultiplier = end.getFullYear() - start.getFullYear() || 1;

  return (endMonth - startMonth) * yearMultiplier;
};

export const getDifference = (start, end) => {
  // get total seconds between the times
  let delta = (end - start) / 1000;
  if (delta < 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  const seconds = delta % 60; // in theory the modulus is not required
  return {
    days,
    hours,
    minutes,
    seconds
  };
};

export const isTimeBefore = (start, end) => {
  return start - end < 0;
};

export const startPad = (unit) => {
  return unit.toString().length === 1 ? `0${unit}` : unit.toString();
};

export const subtractHours = (date, hours) => {
  date.setHours(date.getHours() - hours);
  return date;
};

export const getAppointmentWindow = (endTime, timeZone) => {
  const startTime = subtractHours(new Date(endTime), 1);

  const endTimeFormatted = formatToTimeZone(endTime, "h:mm a", {
    timeZone
  });
  const startTimeFormatted = formatToTimeZone(startTime, "h:mm a", {
    timeZone
  });

  return `${startTimeFormatted} - ${endTimeFormatted}`;
};

export const formatTimeByTimeZone = (time, timeZone, isEndTime) => {
  if (isEndTime) return formatToTimeZone(time, "h a", { timeZone });
  return formatToTimeZone(time, "h", { timeZone });
};

export const formatByTimeZone = (time, timeZone, format = "h:mm a") =>
  formatToTimeZone(time, format, { timeZone });

export const locationTypeFlags = (locationType) => {
  return locationTypeMap[locationType] || {};
};

export const locationTypeLabel = (address) => {
  // location_type: [commercial_property, tax_exempt]
  const locationType = {
    commercial_property: address.commercial_property,
    tax_exempt: address.tax_exempt
  };
  return Object.keys(locationTypeMap).find(
    (key) =>
      JSON.stringify(locationType) === JSON.stringify(locationTypeMap[key])
  );
};

export const locationTypeValues = [
  {
    text: "Residential",
    value: "Residential"
  },
  {
    text: "Commercial",
    value: "Commercial"
  },
  {
    text: "Tax Exempt",
    value: "Tax Exempt"
  }
];

export const locationTypeMap = {
  Residential: {
    commercial_property: false,
    tax_exempt: true
  },
  Commercial: {
    commercial_property: true,
    tax_exempt: false
  },
  "Tax Exempt": {
    commercial_property: true,
    tax_exempt: true
  },
  Other: {
    commercial_property: false,
    tax_exempt: false
  }
};

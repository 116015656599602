<template>
  <v-slide-y-transition>
    <div
      v-if="!loading"
      class="d-flex justify-space-between full-width px-3 py-2 timer elevation-2 mb-2 relative"
    >
      <div class="help">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon color="white" class="subtitle-1 mx-1" v-on="on"
              >mdi-help-circle</v-icon
            >
          </template>
          <span>
            If we arrive after {{ appointment.service_time }}, our overdue clock
            starts and discounts your bill for every minute we're late!
          </span>
        </v-tooltip>
      </div>
      <div class="d-flex full-width">
        <div class="d-flex flex-column align-center flex-1">
          <p class="title d-flex align-flex-start">
            <v-icon color="white" class="subtitle-1 mr-1" style="height: 19px"
              >mdi-clock-outline</v-icon
            >
            {{ lateTime }}
          </p>
          <p class="subtitle-1">Minutes Late</p>
        </div>
        <v-divider
          vertical
          dark
          style="height: 100%; border-color: rgba(255, 255, 255, 0.3)"
          class="mx-3"
        ></v-divider>
        <div class="d-flex flex-column align-center flex-1">
          <p class="title">${{ discount }}</p>
          <p class="subtitle-1">Late Discount</p>
        </div>
      </div>
      <div></div>
    </div>
  </v-slide-y-transition>
</template>

<script>
import { getDifference, startPad } from "@/lib/date";

export default {
  name: "LateFeeTimer",
  props: {
    appointment: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      discount: 0,
      perMinute: 0,
      maxDiscount: 0,
      limit: 3600,
      lateTime: "00:00",
      loading: true,
      interval: null
    };
  },
  mounted() {
    clearInterval(this.interval);
    this.init();
    this.interval = setInterval(this.getMinLate, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    init() {
      const { settings } = this.appointment;
      this.perMinute =
        settings.late_fee_per_minute_cents > 0
          ? settings.late_fee_per_minute_cents / 100
          : 0;
      this.maxDiscount =
        settings.max_late_fee_cents > 0 ? settings.max_late_fee_cents / 100 : 0;
      this.limit = this.maxDiscount / this.perMinute;
      if (Number.isNaN(this.limit)) {
        this.limit = 0;
      }
    },
    getMinLate() {
      const { status, arrival_time, scheduled_time } = this.appointment;
      // Stop timer if tech has arrived
      let comparisonDateTime = new Date();
      if (status === "running") {
        clearInterval(this.interval);
        comparisonDateTime = arrival_time ? new Date(arrival_time) : new Date();
      }
      this.diff = getDifference(new Date(scheduled_time), comparisonDateTime);
      const diffMinutes = this.diff.hours * 60 + this.diff.minutes;
      this.loading = false;
      // Set max discount and stop timer
      let lateTime = "";
      if (this.diff.hours) {
        lateTime = `${startPad(this.diff.hours)}:`;
      }
      lateTime += `${startPad(this.diff.minutes)}:${startPad(
        this.diff.seconds.toFixed()
      )}`;
      this.lateTime = lateTime;
      this.discount = Math.min(diffMinutes * this.perMinute, this.maxDiscount);
    }
  }
};
</script>

<style lang="scss" scoped>
.timer {
  background-color: var(--v-primary-base);
  color: #fff;
  border-radius: 5px;
}
.help {
  position: absolute;
  top: 0px;
  right: 0px;
}
p {
  margin: 0 !important;
  line-height: 21px !important;
}
</style>

<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="
      $vuetify.breakpoint.smAndDown
        ? 'dialog-bottom-transition'
        : 'dialog-transition'
    "
    max-width="768"
  >
    <v-card>
      <v-toolbar dark color="secondary">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edit Account Info</v-toolbar-title>
      </v-toolbar>
      <div v-if="formData" class="pa-4 text-center">
        <v-form ref="form" lazy-validation @submit.prevent="handleSubmit">
          <span class="overline block mb-2 font-bold text-left font-lg"
            >Personal Info</span
          >
          <v-card class="pa-3 bordered-card mb-6 d-flex flex-column">
            <div class="d-flex pr-3">
              <v-text-field
                v-model="formData.first_name"
                outlined
                label="First Name"
                :rules="requiredRules"
                required
                class="mr-2"
              />
              <v-text-field
                v-model="formData.last_name"
                outlined
                label="Last Name"
                :rules="requiredRules"
                class="mr-2"
              />
            </div>

            <EmailFields
              :emails="formData.emails"
              @update="handleUpdate('emails', $event)"
            />
            <Divider />
            <PhoneFields
              :phones="formData.phones"
              @update="handleUpdate('phones', $event)"
              @validate-form="validateForm"
            />
          </v-card>
          <v-btn color="primary" type="submit" rounded :loading="loading"
            >Update Account Info</v-btn
          >
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import API from "serviceshift-ui/api-client";
import { mapState } from "vuex";

import ValidationRules from "@/mixins/validationRules";
import EmailFields from "./EmailFields.vue";
import PhoneFields from "./PhoneFields.vue";

export default {
  components: {
    EmailFields,
    PhoneFields
  },
  mixins: [ValidationRules],
  data() {
    return {
      dialog: true,
      formData: null,
      visible: false,
      loading: false,
      mountErros: false
    };
  },
  computed: {
    ...mapState(["user"])
  },
  watch: {
    user(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.setFormData();
      }
    },
    dialog(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.$emit("hide-modal");
      }
    }
  },
  mounted() {
    this.setFormData();
  },
  methods: {
    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const { first_name, last_name, emails, phones } = this.formData;
        let payload = {
          user: {
            first_name,
            last_name,
            phones: this.cleanData(phones),
            emails: this.cleanData(emails)
          }
        };
        debugger;
        API.user
          .update(payload)
          .then((res) => {
            this.$store.dispatch("setUser", res.data);
            this.$emit("save-success", this.mountErrors);
            this.dialog = false;
          })
          .finally(() => (this.loading = false));
      }
    },
    handleUpdate(field, data) {
      this.formData[field] = data;
    },
    toggleVisible() {
      this.visible = !this.visible;
    },
    cleanData(data) {
      return data.map((item) => {
        if (item.new) {
          delete item.id;
          delete item.new;
        }
        return item;
      });
    },
    validateForm() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        this.mountErrors = true;
      }
    },
    setFormData() {
      this.formData = {
        ...this.user,
        phones: this.user.phones.length
          ? this.user.phones
          : [{ id: -1, new: true }]
      };
    }
  }
};
</script>

<style lang="scss">
.v-application .overline.font-lg {
  font-size: 0.9em !important;
}
.font-bold {
  font-weight: bold !important;
}
</style>

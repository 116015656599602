<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="
      $vuetify.breakpoint.smAndDown
        ? 'dialog-bottom-transition'
        : 'dialog-transition'
    "
    max-width="768"
  >
    <v-card>
      <v-toolbar dark color="secondary">
        <v-btn icon @click="$emit('hide-modal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="editId">Edit Address</v-toolbar-title>
        <v-toolbar-title v-else>Add New Address</v-toolbar-title>
      </v-toolbar>
      <div class="py-6 text-center">
        <v-form
          ref="form"
          v-model="formIsValid"
          lazy-validation
          @submit.prevent="handleSubmit"
        >
          <span class="overline ml-4 block text-left">Address</span>
          <v-card class="ma-4 mt-1 pa-3 bordered-card">
            <AddressField
              ref="address"
              :current-address="currentAddress"
              @validate-form="forceValidate"
            />
            <v-text-field
              v-model="name"
              outlined
              label="Name"
              hint="(Optional) Use to quickly identify an address"
              persistent-hint
            />
            <v-checkbox
              v-model="primary"
              color="primary"
              label="Make this your Billing Address"
              :disabled="primaryDisabled"
            ></v-checkbox>
            <p class="text-left caption grey--text">Location Type</p>
            <v-select
              v-model="selectedLocationType"
              outlined
              label="Location Type"
              :items="locationType"
              :rules="requiredRules"
            ></v-select>
          </v-card>
          <div>
            <v-btn
              v-if="editId"
              color="primary"
              rounded
              class="ml-4"
              type="submit"
              :loading="loading"
              >Update Address</v-btn
            >
            <v-btn
              v-else
              color="primary"
              rounded
              type="submit"
              :loading="loading"
              >Add Address</v-btn
            >
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import API from "serviceshift-ui/api-client";
import { mapState } from "vuex";
import { addressLabel, getAddressComponents } from "@/lib/address";

import AddressField from "@/components/AddressField.vue";
import ValidationRules from "@/mixins/validationRules";
import {
  locationTypeFlags,
  locationTypeLabel,
  locationTypeValues
} from "../lib/locationType";

export default {
  components: {
    AddressField
  },
  mixins: [ValidationRules],
  props: {
    currentAddress: {
      type: Object,
      default: null
    },
    onClose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      editId: null,
      loading: false,
      deleting: false,
      address: {},
      addressDisplay: "",
      name: "",
      selectedLocationType: "",
      primary: false,
      dialog: true,
      locationType: locationTypeValues
    };
  },
  computed: {
    ...mapState(["user"]),
    primaryDisabled() {
      const address = this.user.addresses.find(
        (address) => address.id === this.editId
      );
      return address && address.primary;
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue === false) {
        this.$emit("hide-modal");
      }
    }
  },
  mounted() {
    if (this.currentAddress) {
      this.editAddress();
    }
  },
  methods: {
    editAddress() {
      const address = this.currentAddress;
      this.addressDisplay = `${address.street} ${address.city}, ${address.zip_code}`;
      this.name = address.name;
      this.primary = address.primary;
      this.selectedLocationType =
        locationTypeLabel(address) || address.property_type;

      this.editId = address.id;
    },
    handleDelete() {
      this.deleting = true;
      const addresses = this.user.addresses.filter(
        (address) => address.id !== this.editId
      );
      API.user
        .update({
          user: {
            addresses_attributes: addresses
          }
        })
        .then((res) => {
          this.$store.dispatch("setUser", res.data);
          this.$emit("hide-modal");
        })
        .finally(() => (this.deleting = false));
    },
    handleSubmit() {
      if (
        this.$refs.form.validate() &&
        !this.$refs.address.addressValidationErrorMessage &&
        this.$refs.address.valid
      ) {
        this.loading = true;
        this.address = this.$refs.address.getAddress();

        const payload = this.editId
          ? this.getEditPayload()
          : this.getCreatePayload();
        API.makeRequest(`/v1/users/${this.user.id}`, {
          method: "PUT",
          data: payload
        })
          .then((res) => {
            this.$store.dispatch("setUser", res.data);
            this.$emit("save-success", !!this.editId);
            this.$emit("hide-modal");
          })
          .finally(() => (this.loading = false));
      }
    },
    getCreatePayload() {
      const addressComponents = getAddressComponents({
        ...this.address,
        type: this.selectedLocationType,
        name: this.name,
        primary: this.primary
      });
      return {
        addresses_attributes: [
          {
            ...addressComponents,
            ...locationTypeFlags(this.selectedLocationType)
          }
        ]
      };
    },
    getEditPayload() {
      let addressComponents = null;
      if (this.address.street_number || this.address.street) {
        addressComponents = getAddressComponents({
          ...this.address,
          type: this.selectedLocationType,
          name: this.name,
          primary: this.primary
        });
      }
      const updatedAddress = this.updateAddress(addressComponents);
      return {
        addresses_attributes: [updatedAddress]
      };
    },
    updateAddress(addressComponents) {
      const existingAddresses = this.user.addresses || [];
      const addressToUpdate = existingAddresses.find(
        (address) => address.id === this.editId
      );
      const data = addressComponents || this.getFormData(addressToUpdate);
      return {
        ...addressToUpdate,
        ...data,
        ...locationTypeFlags(this.selectedLocationType)
      };
    },
    getFormData(address) {
      return {
        primary: this.primary,
        property_type: this.selectedLocationType,
        name: this.name || addressLabel(address),
        street: `${address.street}`,
        unit: this.address.suite
      };
    },
    forceValidate() {
      this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss" scoped></style>

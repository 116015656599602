<template>
  <div class="summary">
    <table class="offers-table full-width">
      <!-- First time loading display -->
      <tr v-if="firstTimeLoading" class="offer">
        <td class="full-width pr-4" />
        <td colspan="3">
          <div class="d-flex full-width">
            <Spinner2
              data-cy="loadingPrograms"
              class="ml-auto"
              :size="32"
              color="var(--v-primary-base)"
              message="Loading Available Programs..."
            />
          </div>
        </td>
      </tr>
      <!-- Top Row with Collapsible Display -->
      <tr v-if="!firstTimeLoading && totalOffersAtZeroDown > 0" class="offer">
        <td class="full-width !pr-4">
          <div class="d-flex align-items-center">
            <span class="optionsAvailable"
              >Flexible Financing Options Available</span
            >
          </div>
        </td>
        <td class="text-right pr-2">
          <div v-if="lowestFinancingOffer" data-cy="paymentsAsLowAsText">
            <span>Payments as Low as, with $0 down</span>
          </div>
        </td>
        <td class="paymentsAsLowAsValue text-right">
          <div v-if="lowestFinancingOffer">
            <span data-cy="topRowPaymentsAsLowAsValue">{{
              dollars(lowestFinancingOffer.paymentsAsLowAsCents)
            }}</span>
          </div>
        </td>
        <td></td>
      </tr>
      <!-- Other Offer Display (if available) -->
      <tr
        v-if="!firstTimeLoading && otherOffer"
        class="offer"
        data-cy="otherOffer"
      >
        <td class="full-width pr-4">
          <div class="otherOffer d-flex align-items-center">
            <span class="optionsAvailable">
              {{ otherOfferText }}
            </span>
          </div>
        </td>
        <td class="text-right pr-2">
          <span>Payments as Low as, with $0 down</span>
        </td>
        <td class="paymentsAsLowAsValue text-right">
          <span data-cy="otherOfferValue">{{
            dollars(otherOffer.paymentsAsLowAsCents)
          }}</span>
        </td>
        <td />
      </tr>
    </table>
  </div>
</template>

<script setup lang="ts">
import { minBy } from "lodash";
import { computed } from "vue";

import Spinner2 from "@/components/Spinner2.vue";
import { dollars } from "@/mixins/index.js";
import { useFinancingAvailable } from "./useFinancingAvailable";

const { offerSummaryAtZeroDown, firstTimeLoading, totalOffersAtZeroDown } =
  useFinancingAvailable();

const lowestFinancingOffer = computed(() => {
  const offers = offerSummaryAtZeroDown.value.filter(
    (offer) => offer.lender !== "other"
  );
  return minBy(offers, (offer) => offer.paymentsAsLowAsCents);
});

const otherOffer = computed(() => {
  return offerSummaryAtZeroDown.value.find((offer) => offer.lender === "other");
});

const otherOfferText = computed(() => {
  if (offerSummaryAtZeroDown.value.length > 1) {
    return "Other Financing Plans Available";
  } else {
    return "Financing Plans Available";
  }
});
</script>

<style lang="scss" scoped>
.summary {
  background: #ededed;
  .optionsAvailable {
    font-size: 14px;
  }
  .offers-table {
    background: linear-gradient(274deg, #d6f8d8 0%, #f6f7f7 41.67%);
    border-collapse: collapse;
  }
  .offer {
    border-top: 1px solid #c9cdd0;
    line-height: 1;
    * {
      white-space: nowrap;
    }
    td {
      padding-top: 16px;
      padding-bottom: 16px;
      &:first-child {
        padding-left: 16px;
      }
      &:last-child {
        padding-right: 16px;
      }
    }
  }
  .lenderOffer {
    .logoContainer {
      width: 120px;
    }
    img {
      max-width: 120px;
    }
  }
  .otherOffer {
    .icon {
      font-size: 32px;
      color: #9ca4aa;
    }
  }
  .paymentsAsLowAsValue {
    font-size: 20px;
    font-weight: 600;
  }
  .sections {
    padding: 16px;
    padding-top: 30px;
    box-shadow: 0px 6px 4px -4px rgba(0, 0, 0, 0.3) inset;
    clip-path: inset(-5px 0px -5px -5px);
    > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 900px) {
    font-size: 12px;
    .optionsAvailable {
      font-size: 14px;
    }
    .paymentsAsLowAsValue {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>

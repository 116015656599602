<template>
  <Page :title="title">
    <div v-if="quote" class="full-width">
      <v-alert v-if="$vuetify.breakpoint.xsOnly" type="warning" dense>
        Quote best viewed in Landscape mode. Rotate your device for the best
        experience.
      </v-alert>
      <div v-if="quote.links.quote_proceed" class="mb-6 d-flex align-center">
        <div>
          <router-link :to="{ name: 'quotes' }">
            <i class="mr-3 fa fa-arrow-left"></i>
            <span>Return to quotes</span>
          </router-link>
        </div>
        <v-btn
          class="ml-auto"
          type="submit"
          color="primary"
          :loading="loading"
          @click="toggleProceedModal"
          >Proceed with Service</v-btn
        >
      </div>
      <span class="overline text-left full-width">Quote</span>
      <v-card
        class="full-width bordered-card mb-4 pa-3"
        style="font-size: 0.75rem !important; overflow-y: scroll"
      >
        <Quote
          :quote="quote"
          :customizations="customizations"
          tech-mode
          :class="{ 'hidden-signature': hideSignature }"
        >
          <template
            v-if="financingEnabled || paymentsAsLowAsEnabled"
            #financingOptions
          >
            <FinancingAvailable
              class="mt-4"
              :quote="quote"
              :payments-as-low-as-enabled="paymentsAsLowAsEnabled"
              :financing-offers-enabled="financingEnabled"
            />
          </template>
        </Quote>
        <div v-if="quote.links.quote_proceed" class="text-center my-6">
          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
            @click="toggleProceedModal"
            >Proceed with Service</v-btn
          >
        </div>
      </v-card>
      <quote-proceed-modal
        v-if="proceedModal"
        :quote="quote"
        @hide-modal="toggleProceedModal"
        @save-success="toggleProceedSuccess"
      ></quote-proceed-modal>
      <success-toast v-if="proceedSuccess" @hide-modal="toggleProceedSuccess">
        <div slot="body">
          <p>Your request for service has been successfully submitted.</p>
        </div>
      </success-toast>
    </div>
  </Page>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Page from "@/components/Page.vue";
import QuoteProceedModal from "@/components/QuoteProceedModal.vue";
import SuccessToast from "@/components/SuccessToast.vue";
import FinancingAvailable from "@/modules/financing/financingAvailable/FinancingAvailable.vue";

export default {
  components: {
    FinancingAvailable,
    Page,
    QuoteProceedModal,
    SuccessToast
  },
  data() {
    return {
      loading: false,
      proceedModal: false,
      proceedSuccess: false
    };
  },
  computed: {
    ...mapState({
      quote: "currentQuote",
      customizations: ({ customizations, tenant }) => {
        return { ...customizations, Tenant: tenant };
      },
      hideSignature() {
        return ["pending", "draft"].includes(this.quote.status);
      },
      paymentsAsLowAsEnabled({ tenant }) {
        if (!tenant) return false;
        return Boolean(tenant.feature_flags.payments_as_low_as);
      },
      financingEnabled({ tenant }) {
        if (!tenant) return false;
        return Boolean(tenant.feature_flags.financing);
      }
    }),
    title() {
      return `Quote #${this.$route.params.id} Summary`;
    }
  },
  mounted() {
    this.fetchQuote(this.$route.params.id);
  },
  methods: {
    ...mapActions(["fetchQuote"]),
    toggleProceedModal() {
      this.proceedModal = !this.proceedModal;
    },
    toggleProceedSuccess() {
      this.proceedSuccess = !this.proceedSuccess;
    }
  }
};
</script>

<style lang="scss">
.hidden-signature .signature.signature {
  display: none !important;
}
.quote-body {
  min-width: 415px;
}
</style>
